@import '../styles/vars';
@import '../styles/responsive';

.block {
  position: relative;
  display: block;
}

.page__foreground {
  .block > div,
  .block > ul {
    margin-top: calc($block-spacing / 2);
    margin-bottom: calc($block-spacing / 2);

    @include only-on(lgUp) {
      margin-top: 0.75 * $block-spacing;
      margin-bottom: 0.75 * $block-spacing;
    }

    @include only-on(xl) {
      margin-top: $block-spacing;
      margin-bottom: $block-spacing;
    }
  }
}

.block > div.homepageHeroBlock {
  margin-top: 0px;
}
