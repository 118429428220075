@import "../../styles/mixins";
@import "../../styles/typography";
@import "../../styles/responsive";
@import "../../styles/spacing";
@import "../../styles/utility";
@import "../../styles/colors";

.page__plain {
  color: $vroom-dark-brown;
  overflow: hidden;
}

.page__plain__content {
  margin: auto $spacing-2;
  padding: $spacing-3 $spacing-2;
  border-radius: 5px;

  @include only-on(xl) {
    margin: auto;
    width: 100%;
    padding: $spacing-5 $spacing-3 $spacing-3;
    max-width: 960px;
  }
}

.page__plain__header {
  .heroBlock__header {
    margin: 0;
    color: $vroom-orange;
  }
}

.page__plain__bg {
  background-color: $background-light-blue;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-left: -20px;
  margin-right: -20px;
  @include only-on(lg) {
    margin: auto;
    padding-top: 100px;
    padding-bottom: 150px;
  }
  @include only-on(xl) {
    padding-top: 125px;
    padding-bottom: 200px;
  }
}

.page__plain {
  .footer {
    padding-top: 0px;
    .footer__content {
      border: none;
    }
  }
}
