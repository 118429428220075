@import "../styles/vars";
@import "../styles/colors";
@import "../styles/mixins";

.footer {
  position: relative;
  z-index: 1200;
  padding: 0px 20px 20px 20px;
  @include only-on(mdDown) {
    padding: 0px 40px 40px 40px;
  }
}

.footer__content {
  max-width: $break-sm;
  margin-left: auto;
  margin-right: auto;
  border-top: solid 2px $vroom-teal;
  padding-top: 60px;

  @include only-on(lg) {
    max-width: $break-lg;
  }

  @include only-on(xl) {
    max-width: $break-xl;
  }
}

.footer__menuItem {
  text-align: center;
  @include only-on(lgUp) {
    text-align: left;
  }
}

.footer__menuItem a {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
}

.footer__col {
  align-items: flex-start;
  justify-content: flex-start;
  @include only-on(lgUp) {
    flex: 1 0 auto;
  }
}

@include only-on(mdDown) {
  .footer__col:last-child {
    margin-top: 30px;
  }
}

.footer__grid {
  @include only-on(lgUp) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
  }
}

.footer__downloadLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  p {
    margin: 0px;
    color: $vroom-teal;
    font-weight: 500;
    text-align: center;

    @include only-on(lgUp) {
      text-align: left;
    }
  }

  .footer__downloadIcons {
    text-align: center;
    flex: initial;
    margin-left: 20px;
    color: $vroom-teal;

    @include only-on(lgUp) {
      flex: 1 0 auto;
      text-align: left;
    }

    .icon {
      width: 25px;
      height: 25px;
      margin-left: 10px;
    }
  }
}

.footer__social {
  margin-top: 60px;
  margin-bottom: 30px;
  text-align: center;

  .icon {
    margin-left: 10px;
    margin-right: 10px;
  }

  @include only-on(lgUp) {
    .icon {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}

.copyright-text {
  color: $vroom-teal;
  margin: 0px auto 20px auto;
  text-align: center;
  font-weight: 500;
  a {
    font-size: 16px;
  }
}
