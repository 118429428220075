@import './styles/colors';
@import './styles/mixins';
@import './styles/typography';
@import './styles/responsive';

.vroomForm__form {
  flex: 0 0 45%;
  @include only-on(mdUp) {
    margin-top: -10px;
  }
}

.vroomForm__row {
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vroomForm__row--centered {
  justify-content: center;
  text-align: center;
}

.vroomForm__submittingIcon {
  color: white;
}

.vroomForm__field--half {
  flex: 0 0 48%;
}

.vroomForm__field--full {
  flex: 0 0 100%;
}

.vroomForm__submitField {
  margin-top: 50px;
  text-align: center;
}

.vroomForm__submitButton {
  @include cta;
  line-height: normal;
  border: none;
  display: inline-block;
  text-align: center;
  justify-content: center;
  height: 54px;
}

.vroomForm__submitButton .vroomForm__submittingIcon {
  color: white;
}

.vroomForm__errorMessage {
  margin-top: 10px;
  margin-left: 12px;
  color: red;
}

.vroomForm__successMessage {
  margin: 30px auto;
  max-width: 300px;
  @extend .bodyText;
  color: $vroom-teal-hover;
}

//Hacks for styling inputs... material UI props don't seem to be working
//Need to research more
.vroomForm__row > div > label {
  color: $vroom-dark-brown;
}

.vroomForm__row > div > div:after {
  background-color: $vroom-orange;
}
