@import '../styles/vars';
@import '../styles/mixins';
@import '../styles/colors';
@import '../styles/responsive';

.header {
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  position: absolute;
  width: 100%;
  z-index: 2400;
  transform: translateY(0px);

  &.fixed {
    position: fixed;
  }

  &.animate {
    transition: transform 0.4s ease-in-out;
  }

  &.hidden {
    transform: translateY(-140px);
  }

  &.static {
    transition: none;
  }
}

.header__menuItem--active {
  color: $vroom-teal-hover;
}

.header__menuItem--selected a {
  text-decoration: underline;
}

//hack to hide CTA button on top of homepage
.homepage .header.static {
  .header__menuItem:last-child {
    display: none;
  }
}

.header__content {
  display: flex;
  height: 60px;
  align-items: center;
  padding: 0px 20px;

  @include only-on(lgUp) {
    height: 95px;
    padding-left: 40px;
    align-items: center;
  }

  @include only-on(xl) {
    padding-left: 20px;
    // max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }
}

.header__logo {
  display: inline-block;
  flex: 1 0 auto;
}

.header__logo svg {
  width: 75px;
  @include only-on(xl) {
    width: 150px;
  }
}

.header__menu {
  display: flex;
  align-items: center;
}

.header__menuItem {
  vertical-align: middle;
}

.header__menuItem a {
  display: inline-block;
  margin-left: 40px;

  @include only-on(lg) {
    margin-left: 25px;
  }
}

.header__menuItem {
  display: none;
  @include only-on(xl) {
    display: block;
  }
}

.header__mobileMenu {
  vertical-align: middle;
  @include only-on(xl) {
    display: none;
  }
}

.header__menuItem:last-child a {
  @include cta(195px, 54px);
  border: solid 2px $vroom-teal;
  color: $vroom-teal;
  background: none;

  &:hover {
    border-color: $vroom-teal-hover;
  }

  @include only-on(lg) {
    min-width: 125px;
    line-height: 45px;
  }
}

.headerMobileMenu__menu {
  position: absolute;
  left: 0;
  top: 60px;
  width: 100vw;
  height: 0px;
  overflow: hidden;
  background-color: white;
  text-align: center;
  transition: height 0.5s ease-in-out;
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25);

  .header__menuItem {
    display: block;
    a {
      margin: 23px 0px;
      font-size: 20px;
    }
    &:last-child {
      a {
        min-width: 200px;
        line-height: 50px;
      }
    }
  }
}

.headerMobileMenu__menu--open {
  height: 400px;
}
