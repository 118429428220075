@import '../../styles/mixins';
@import '../../styles/vars';
@import '../../styles/typography';
@import '../../styles/responsive';

.videoLink--small {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  @include only-on(xl) {
    margin-bottom: 0px;
  }
}

.videoLink--small iframe {
  background-color: black;
}

.videoLink__text {
  text-align: center;
  max-width: $block-text-width-max;
  margin-left: auto;
  margin-right: auto;
}

.videoLink__header {
  @extend .titleText;
  margin: 20px auto;
  max-width: 300px;

  @include only-on(lgUp) {
    max-width: none;
  }
}

.videoLink__body {
  @extend .bodyText;
}

.videoLink__secondaryText {
  margin-top: 20px;
  @extend .bodyText;
}

.videoCarouselBlock__secondaryLinks {
  margin: 40px 0px;
  text-align: center;

  @include only-on(xl) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 60px 0px 150px 0px;
  }
}

.videoCarouselBlock {
  .videoLink {
    iframe {
      width: 100%;
      height: 250px;

      @include only-on(lg) {
        height: 400px;
      }

      @include only-on(xl) {
        height: 560px;
      }
    }
  }

  .videoLink--small {
    iframe {
      height: auto;
    }

    @include only-on(xl) {
      iframe {
        height: 175px;
      }
    }
  }
}
