@import './responsive.scss';

@include only-on(mdDown) {
  $dotSize: 16px;
  .slick-dots {
    bottom: -20px;
    & li {
      width: $dotSize;
      height: $dotSize;
    }

    & li button {
      width: $dotSize;
      height: $dotSize;
    }

    & li button:before {
      width: $dotSize;
      height: $dotSize;
      font-size: $dotSize;
      color: #00B9B4;
      opacity: 0.15;
    }

    & li.slick-active button:before {
      color: #00B9B4;
      opacity: 1;
    }
  }
}

@include only-on(lgUp) {
  $dotSize: 20px;
  .slick-dots {
    bottom: -40px;
    & li {
      width: $dotSize;
      height: $dotSize;
    }

    & li button {
      width: $dotSize;
      height: $dotSize;
    }

    & li button:before {
      width: $dotSize;
      height: $dotSize;
      font-size: $dotSize;
      color: #00B9B4;
      opacity: 0.25;
    }

    & li.slick-active button:before {
      opacity: 1;
      color: #00B9B4;
    }
  }
}
