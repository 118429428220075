@import './colors';

.icon {
  width: 20px;
  height: 20px;
  color: $vroom-teal;
  fill: $vroom-teal;
}

.logoIcon {
  width: 148px;
  height: 33px;
  color: $vroom-orange;
  fill: $vroom-orange;
}

a:hover .icon {
  color: $vroom-teal-hover;
  fill: $vroom-teal-hover;
}
