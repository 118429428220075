@import "./colors";
@import "./spacing";

.slide_indicators {
  margin: $spacing-3 0;
}

.slide_indicators__dot {
  border: none;
  display: inline-block;
  cursor: pointer;
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: $vroom-teal;
  margin-right: 10px;
  opacity: 0.5;
  transition: opacity 0.3s ease-in;
}

.slide_indicators__dot--active {
  opacity: 1;
}
