@import '../../../styles/mixins';

.leavesAnimation {
  position: absolute;
  left: 0;
  opacity: 0;
  top: -70px;
  width: 150px;

  @include only-on(mdDown) {
    top: -20px;
    width: 60px;
  }

  .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#FFB900;}
	.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FA9600;}
}

.textMediaBlock--Left {
  .leavesAnimation {
    left: auto;
    right: 0px;
  }
}
