@import '../../styles/responsive';
@import '../../styles/spacing';
@import '../../styles/typography';

.page__default {
  background-color: white;
  color: black;
  padding-top: 60px;

  @include only-on(lgUp) {
    padding-top: 95px;
  }

  .ctaBlock,
  .textBlock {
    padding: $spacing-2 0;

    @include only-on(xl) {
      padding: $spacing-3 0;
    }
  }

  .quote__quote {
    @extend .headline_inner;
  }

  .quote__author {
    @extend .headline_sub_inner;
    color: black;
  }

  .quote__authorDescription {
    @extend .text_small;
    color: black;
  }

  .threeColumnsBlock,
  .videoCarouselBlock {
    padding: 0 $spacing-3 $spacing-3;

    @include only-on(lg) {
      padding: 0 $spacing-4 $spacing-3;
    }

    @include only-on(xl) {
      padding: 0 $spacing-5 $spacing-4;
    }
  }

  .threeColumnsBlock__title {
    @extend .headline_small;
    font-weight: normal;
    color: black;
  }

  .videoLink__header {
    @extend .headline_inner;
    color: black;
  }

  .videoLink__secondaryText {
    @extend .headline_sub_inner;
    color: $vroom-teal;
  }

  .videoCarouselBlock__secondaryLinks {
    margin: $spacing-3 0 0;

    @include only-on(xl) {
      margin: $spacing-4 0 0;
    }
  }
}
