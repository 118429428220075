.pageBg--orange .page__bgPattern {
  background-color: #fa9600;
  background-image: url("/static/images/backgrounds/orange.svg");
  background-repeat: repeat;
  background-size: 968px 1056px;
}

.pageBg--blue .page__bgPattern {
  background-color: #d7f5f5;
  background-image: url("/static/images/backgrounds/teal.svg");
  background-repeat: repeat;
  background-size: 968px 1056px;
}

.pageBg--tan .page__bgPattern {
  background-color: #faf5e6;
  background-image: url("/static/images/backgrounds/tan.svg");
  background-repeat: repeat;
  background-size: 968px 1056px;
}
