@import '../../styles/mixins';
@import '../../styles/vars';
@import '../../styles/typography';
@import '../../styles/responsive';

.page--standard {
  margin-left: 20px;
  margin-right: 20px;

  @include only-on(xl) {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.standardLayout {
  .page__bgPattern {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    @include only-on(lgUp) {
      top: 0;
    }
  }

  .page__bgHeader {
    top: $navHeight;
    left: 0px;
    height: $bgHeaderHeight;
    width: 100%;
    position: fixed;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    @include only-on(lgUp) {
      top: $navHeight-lg;
      height: $bgHeaderHeight-lg;
    }

    &.Plx--bellow {
      display: none;
    }
  }

  .page__foreground {
    @include plxForeground;
  }

  .page__bgContent {
    width: 100%;
    @include only-on(lgUp) {
      max-width: $block-text-width-max-xl;
    }

    @include only-on(mdDown) {
      max-width: 400px;
    }

    @include only-on(sm) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}
