@import '../../styles/mixins';
@import '../../styles/vars';
@import '../../styles/typography';
@import '../../styles/responsive';

.textBlock__body {
  p {
    @extend .bodyText;
  }
}

.textBlock--Rocket {
  p {
    @extend .quoteText;
    font-weight: 500;
    text-align: center;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 250px;

    @include only-on(lgUp) {
      max-width: $block-text-width-max;
    }

    @include only-on(xl) {
      max-width: $block-text-width-max-xl;
    }
  }

  .rocketSingle {
    top: 200px;
    left: -50px;
  }
}

.legal {
  .textBlock__body {
    padding: 0px 20px;
    text-align: left;
    @include only-on(lgUp) {
      padding: 0px 50px;
    }

    h2 {
      font-size: 42px;
      margin-top: 50px;
      margin-bottom: 30px;
      color: $vroom-orange;
      @include only-on(mdDown) {
        font-size: 22px;
      }
    }

    h3 {
      font-size: 26px;
      margin-top: 20px;
      margin-bottom: 20px;

      @include only-on(mdDown) {
        font-size: 14px;
      }
    }

    ul {
      margin: 0;
      padding-left: 20px;
      margin-top: 20px;
      margin-left: 0px;
      margin-bottom: 20px;

      @include only-on(mdDown) {
        font-size: 14px;
      }
    }

    li {
      margin-top: 10px;
    }

    p {
      margin-bottom: 20px;
    }
  }
}
