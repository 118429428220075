@import '../styles/colors.scss';
@import '../styles/icon.scss';
@import '../styles/mixins.scss';
@import '../styles/typography.scss';
@import '../styles/vars.scss';
@import '../styles/slick_carousel.scss';
@import '../styles/carousel.scss';
@import '../styles/responsive.scss';

h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
span {
  margin: 0px;
}

html {
  font-family: var(--omnes-font);
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

* {
  min-height: 0;
  min-width: 0;
}

a {
  @extend .linkText;
}

p {
  margin-bottom: 20px;
}

body {
  margin: 0;
}

.rootWrapper {
  color: $vroom-dark-brown;
  position: relative;
  max-width: 100vw;
  overflow: hidden;
  z-index: 0;
  transition: background 1.25s ease-in-out;
  // margin-top: 60px;
  // @include only-on(lgUp) {
  //   margin-top: 95px;
  // }
}

.rootWrapper__bg {
  opacity: 0;
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 0;
  transition: opacity 1s ease-in-out;
}

.rootWrapper__bg--pattern {
  opacity: 1;
  background-color: green;
}

.page__foreground .block > .contactForm {
  margin-top: 200px;
}

.page__foreground .block .quoteBlock {
  margin-top: 75px;
  margin-bottom: 75px;

  @include only-on(lgUp) {
    margin-top: 150px;
    margin-bottom: 150px;
  }
}

.page__foreground .block > div.brainBuildingBasics {
  @include only-on(lgUp) {
    margin-top: -75px;
    margin-bottom: -50px;
  }

  @include only-on(mdDown) {
    margin-top: -75px;
    margin-bottom: -75px;
  }
}

.faq:first-child {
  margin-top: 40px;
}

.faq:last-child {
  margin-bottom: 40px;
}
