@import '../../styles/mixins';
@import '../../styles/typography';
@import '../../styles/responsive';
@import '../../styles/vars';

.page__foreground .block:not(:first-child) > div.heroBlock {
  @include only-on(mdDown) {
    margin-bottom: -25px;
  }
  @include only-on(lgUp) {
    margin-top: 120px;
    // margin-bottom: 100px;
  }
}

.heroBlock__header {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 250px;

  @include only-on(lgUp) {
    max-width: $block-text-width-max;
  }
  @include only-on(xl) {
    max-width: none;
  }
}

.heroBlock__body {
  @extend .bodyText;
  text-align: center;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;

  p:last-child {
    margin-bottom: 0px;
  }
}

.heroBlock--LargeHeader {
  .heroBlock__header {
    @extend .headerTextLarge;

    @include only-on(lgUp) {
      max-width: $block-text-width-max;
    }
    @include only-on(xl) {
      max-width: $block-text-width-max-xl;
    }
  }

  .heroBlock__body {
    @include only-on(mdDown) {
      font-size: 16px;
      font-weight: 500;
    }

    @include only-on(lgUp) {
      font-size: 24px;
      font-weight: 500;
      max-width: 500px;
    }

    @include only-on(xl) {
      max-width: 700px;
    }
  }
}

.heroBlock--StackedSmallHeader {
  .heroBlock__header {
    @extend .headerTextSmall;

    @include only-on(lg) {
      max-width: 500px;
    }

    @include only-on(xl) {
      max-width: $block-text-width-max-xl;
    }
  }

  .heroBlock__body {
    @include only-on(lg) {
      max-width: 500px;
    }

    @include only-on(xl) {
      max-width: 700px;
    }
  }
}

.heroBlock--SplitSmallHeader {
  @include only-on(lgUp) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;

    .heroBlock__header {
      margin-right: 25px;
      text-align: left;
    }

    .heroBlock__body {
      margin-top: 5px;
      margin-left: 25px;
      max-width: none;
      text-align: left;
    }
  }

  .heroBlock__header {
    @extend .headerTextSmall;
  }

  .heroBlock__body {
    @extend .bodyText;
  }
}

.pageBg--orange {
  .page__bgContent {
    .heroBlock__header {
      color: white;
    }

    .heroBlock__body {
      color: white;
    }
  }
}

.pageBg--blue {
  .page__bgContent {
    .heroBlock__header {
      color: $vroom-teal-hover;
    }

    .heroBlock__body {
      color: $vroom-teal-hover;
    }
  }
}
